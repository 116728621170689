import React from "react"
import Flex from "../../../utils/flex"
import { StaticImage } from "gatsby-plugin-image"
import { steps } from "./steps"
import ctl from "@netlify/classnames-template-literals"
import WebinarStepItem from "./list-item"
import { Container } from "../../../utils"
import "./how-our-webinars-work.css"
import { DesktopBreakpoint } from "../../../breakpoints"

const HowOurWebinarsWork = () => {
  return (
    <div className={wrapperStyle}>
      <div className='md-max:hidden border-t border-b border-brownish-grey bg-light-tangerine absolute top-0 w-full -z-1 orange-border'/>
      <Container size="large">
        <Flex
          reverseMobile
          alignTop
          firstItemClassName='md-max:relative lg:!min-w-[450px]'
          firstItem={
            <div>
              <StaticImage
                src="../../../../assets/images/pages/webinars/man-pressing-laptop.webp"
                alt="Man working on trading"
                className={imageStyle}
              />
              <div className={mobileRectangle} />
            </div>
          }
          secondItemClassName="md-max:mt-0 md:mt-3 md-max:px-4"
          secondItem={
            <>
              <h2 className={headingStyle}>How our Webinars work</h2>
              <p className={paragraphStyle}>
                All our webinars focus on key trading topics and are <DesktopBreakpoint/>
                poised to help you hone your skills as a professional financial <DesktopBreakpoint/>
                markets trader. Our webinars are interactive, meaning that you <DesktopBreakpoint/>
                can ask questions and have them answered instantly.
              </p>
              <ol>
                {steps.map((benefit, index) => (
                  <WebinarStepItem key={index} item={benefit} />
                ))}
              </ol>
            </>
          }
          className="md:mt-0 md:!pt-[43px] lg-max:gap-y-[90px]"
        />
      </Container>
    </div>

  )
}

const wrapperStyle = ctl(`
  relative
  md:mt-[120px]
`)
const imageStyle = ctl(
  `md:w-[429px] md:mt-0 mt-0 md:text-center text-left z-10`
)
const headingStyle = ctl(`font-extrabold text-left md-max:text-[27px] md-max:leading-[38px]`)
const paragraphStyle = ctl(`text-left mt-4 md:mt-[30px] mb-10 md-max:max-w-[332px] md:min-w-[470px]`)

const mobileRectangle = ctl(
  `
    md:hidden
    w-[100vw] h-[289px]
    top-[50%] translate-y-[-50%] 
    left-[-24px]
    border-t border-b border-brownish-grey bg-light-tangerine
    absolute
  `
)

export default HowOurWebinarsWork
