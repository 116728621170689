import * as React from "react"
import Layout from "../../components/layout"
import { HowOurWebinarsWork, WebinarsIntro } from "../../components/pages/webinars"
import PastWebinars from "../../components/pages/webinars/past"
import Container from "../../components/utils/container"

const WebinarAcademyPage = () => (
  <Layout
    title="Trading Courses | Learn about the financial market"
    description='Our webinars are geared towards increasing your knowledge of the financial market and helping you hone your trading skills to give you the confidence you need to trade the markets!'
  >
    <div className="relative">
      <Container size="large">
        <WebinarsIntro />
      </Container>
    </div>
    <HowOurWebinarsWork />
    <PastWebinars />
  </Layout>
)

export default WebinarAcademyPage
