import React, { useState } from "react"
import ctl from "@netlify/classnames-template-literals"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Pagination } from "swiper"
import { splitArray } from "../../../../utils"
import playIcon from "../../../../assets/icons/play-stroke.svg"

import "swiper/css/pagination"
import "./webinar.css"
import InPagePlayer from "../../../utils/in-page-player"


SwiperCore.use([Pagination])

const ListPastWebinars = ({ webinars }) => {
  return (
    <>
      <div className='block sm:hidden mt-[40px] webinars-slider'>
        {splitArray(webinars, 3).map((webinarRow, row) => {
          return (
            <Swiper slidesPerView={1.3} spaceBetween={40}>
              {webinarRow.map((webinar, index) => (
                <SwiperSlide key={`${row}-${index}`}>
                  <WebinarSlide {...webinar}/>
                </SwiperSlide>
              ))}
            </Swiper>
          )
        })}
      </div>
      <div className='hidden sm:grid grid-cols-3 gap-x-[30px] sm:gap-x-[40px] md:gap-x-[80px] xl:gap-x-[120px] gap-y-[50px] lg:pb-4 mt-[68px]'>
        {webinars.map(WebinarSlide)}
      </div>
    </>
  )
}

const WebinarSlide = ({ image, title, excerpt, url }) => {
  const [showPlayer, setShowPlayer] = useState(false);

  const toggleShowPlayer = () => setShowPlayer(prev => !prev);

  return (
    <>
      <article
        onClick={toggleShowPlayer}
        rel="noopener noreferrer"
        className="relative cursor-pointer past-webinar h-[300px] md:h-[320px] group"
      >
        <div className='md-max:hidden absolute h-full w-full left-0 top-0 bg-black bg-opacity-40 z-10 flex items-center justify-center opacity-0 duration-300 group-hover:opacity-100'>
          <div
            rel="noopener noreferrer"
            className='text-white bg-transparent rounded-lg border border-white px-[23px] py-[6px] flex items-center gap-x-3'
          >
            <span>
              Click to watch
            </span>
            <img src={playIcon} alt="Click to watch" />
          </div>
        </div>
        <figure>{image}</figure>
        <div
          className={detailsWrapperStyle}
        >
          <h3 className={titleStyle}>{title}</h3>
          <p
            dangerouslySetInnerHTML={{ __html: excerpt }}
            className={excerptStyle}
          />
        </div>
      </article>
      <InPagePlayer show={showPlayer} videoUrl={url} onClose={toggleShowPlayer}/>
    </>
  )
}

const listPastWebinarGridStyle = ctl(
  `pb-[30px] md:mt-[68px] mt-[40px]`
)

const detailsWrapperStyle = ctl(`md-max:px-[14px] p-[20px] block`)

const titleStyle = ctl(`mt-0 text-[20px] md-max:hidden`)
const excerptStyle = ctl(`mt-0 md:mt-[15px]`)

export default ListPastWebinars
