import React from "react"
import ctl from "@netlify/classnames-template-literals"

const WebinarStepItem = ({ item }) => {
  return (
    <li className={listItemStyle}>
      <span className={listStyle}/>
      <p className={listParagraphStyle}>{item}</p>
    </li>
  )
}

const listItemStyle = ctl(`flex mt-[24px]`)
const listParagraphStyle = ctl(`mt-0 text-left`)
const listStyle = ctl(
  `w-[13px] h-[13px] rounded-full bg-blue inline-block mr-[30px] flex-shrink-0 items-start md:mt-[10px] mt-[6px]`
)

export default WebinarStepItem
