import React from "react"
import Flex from "../../../utils/flex"

import ctl from "@netlify/classnames-template-literals"
import Button from "../../../utils/button"
import WebinarsAnimation from "../../../animations/webinars-animation"

const WebinarsIntro = () => {
  return (
    <Flex
      alignTop
      isAtTopOfPage
      justifyStart
      className='gap-x-[145px]'
      firstItemClassName='md:ml-[29px] md:!min-w-[455px]'
      firstItem={
        <>
          <h1 className='font-extrabold md-max:hidden md:text-[40px] md:leading-[1.25]'>
            Learn the ropes.<br/>
            Understand the markets. <br/>
            Become a pro trader.
          </h1>
          <h2 className='font-extrabold md:hidden '>
            Learn the ropes,<br/>
            understand the markets. <br/>
            Become a pro trader.
          </h2>
          <p className='text-lg mt-[28px]'>
            Join us every Tuesday and Thursday for a wholesome <br/>
            practical experience as we study the financial markets<br/>
            & then trade it.
          </p>

          <Button url='/academy/webinars/upcoming' label="View upcoming webinars" className='!text-lg' hasArrow type="tertiary" />
        </>
      }
      secondItem={
        <>
          <div className={imageStyle}>
            <WebinarsAnimation />
          </div>
        </>
      }
    />
  )
}

const imageStyle = ctl(`md:w-[384px] md-max:max-w-[95%] md-max:w-[360px] mx-auto relative z-10`)
export default WebinarsIntro
