import React from 'react'

export const steps = [
  `Go from zero to pro.`,
  <span>
    Learn the basics of financial markets <br/> trading. Beat the markets.
  </span>,
  `Live trading & market analysis.`,
  <span>
    AMA (Ask Me Anything) sessions where you <br/> can ask as many questions as you’d like.
  </span>
]
