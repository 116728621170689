// split an array into subArrays of equal size
export const splitArray = (arr, size) => {
  const num_of_subArrays = Math.floor(arr.length / size)
  const result = []
  for (let i = 0; i < num_of_subArrays; i++) {
    const startingPoint = i * size
    result.push(arr.slice(startingPoint, startingPoint + size))
  }

  return result
}