import React from "react"
import Container from "../../../utils/container"
import ctl from "@netlify/classnames-template-literals"
import ListPastWebinars from "./list"
import { StaticImage } from "gatsby-plugin-image"

const PastWebinars = () => {
  return (
    <Container disableXLPadding className={containerStyle} size="large">
      <h2 className="md-max:text-[25px] md:text-center">View past webinars</h2>
      <ListPastWebinars webinars={pastWebinars} />
    </Container>
  )
}

const containerStyle = ctl(`md:mt-[114px] mt-[65px] md:px-[3%]`)

const pastWebinars = [
  {
    title: `Ask Me Anything`,
    excerpt: `An interactive Q&A Webinar
    <br/> on trading.`,
    url: 'https://youtu.be/auWX-kEQW-0',
    image: (
      <StaticImage
        src="../../../../assets/images/pages/webinars/past/ask-me-anything.webp"
        alt=""
      />
    ),
  },
  {
    title: `Profit Cross Strategy`,
    excerpt: `In this webinar, we would teach about the profit cross strategy which has a high win `,
    url: `https://www.youtube.com/watch?v=o-OnWia71Jo`,
    image: (
      <StaticImage
        src="../../../../assets/images/pages/webinars/past/profit-cross-strategy.webp"
        alt=""
      />
    ),
  },
  {
    title: `Trading with little Capital`,
    excerpt: `We would be discussing undercapitalization and how it affects a trader’s ability to make `,
    url: `https://www.youtube.com/watch?v=CsirUmTNz3A`,
    image: (
      <StaticImage
        src="../../../../assets/images/pages/webinars/past/trading-with-little-capital.webp"
        alt=""
      />
    ),
  },
  {
    title: `Trading Basics for everyone`,
    excerpt: `For traders—especially those with <br/>limited funds—day trading in small
    `,
    url: `https://www.youtube.com/watch?v=NGvvEB3Kqtw`,
    image: (
      <StaticImage
        src="../../../../assets/images/pages/webinars/past/trading-basics.webp"
        alt=""
      />
    ),
  },
  {
    title: `Risk Management`,
    excerpt: `Risk management refers to implementing a set of rules and measures to ensure`,
    url: `https://www.youtube.com/watch?v=duqHfki8ozs`,
    image: (
      <StaticImage
        src="../../../../assets/images/pages/webinars/past/risk-management.webp"
        alt=""
      />
    ),
  },
  {
    title: `Trading Indicators`,
    excerpt: `Trading indicators are used with a strategy to maximize returns. Ever wondered how`,
    url: `https://www.youtube.com/watch?v=xBvm7joAhzc`,
    image: (
      <StaticImage
        src="../../../../assets/images/pages/webinars/past/trading-indicators.webp"
        alt=""
      />
    ),
  },
  {
    title: `Importance of Time & Price`,
    excerpt: `If something comes close to the holy grail in trading, that’s price and time.`,
    url: `https://www.youtube.com/watch?v=2jy1zCCodaU`,
    image: (
      <StaticImage
        src="../../../../assets/images/pages/webinars/past/importance-of-time-and-money.webp"
        alt=""
      />
    ),
  },
  {
    title: `Financial Instruments for Beginners`,
    excerpt: `Having a clear understanding of a given financial instrument as well as the factors`,
    url: `https://www.youtube.com/watch?v=GyFlKWOyzU4`,
    image: (
      <StaticImage
        src="../../../../assets/images/pages/webinars/past/financial-instruments-for-beginners.webp"
        alt=""
      />
    ),
  },
  {
    title: `Trading Gold`,
    excerpt: `We’re going to talk about gold trading for beginners and how you can build your`,
    url: `https://www.youtube.com/watch?v=oTjbuJjsjEY`,
    image: (
      <StaticImage
        src="../../../../assets/images/pages/webinars/past/trading-gold.webp"
        alt=""
      />
    ),
  },
]
export default PastWebinars
